import ApolloClient from '@apollo/client';
import Cookies from 'js-cookie';
import JwtDecode from 'jwt-decode';
import { getCookieDomain, getUrlOf } from '../utils/urls';
import { gtmPushLoginEvent } from '../common/gtm';

const AUTH_CLIENT_ID = `${process.env.GATSBY_AUTH_CLIENT_ID}`;
const AUTH_AUTHORIZE_URL = '/api/v1/authorize';
const AUTH_LOGIN_URL = '/api/v1/login';
const SET_LAST_URL = '/index/set-last-url';
export const AUTH_TOKEN_URL = '/index/auth-token';

// Local Storage
export const SESSION_STORAGE_CUSTOMER_NUMBER = 'SESSION_STORAGE_CUSTOMER_NUMBER';
export const SESSION_STORAGE_JWT = 'SESSION_STORAGE_JWT';
const SESSION_STORAGE_AUTH_USER_ID = 'authUserId';

/**
 * @param login: string;
 * @param password: string;
 */
export interface AmsLoginData {
  login: string;
  password: string;
  exa_auth_remember?: boolean;
}

export interface User {
  c: string; // customer ID
  cc: string; // customer Code
  s: number; // Exaprint society ID
  t: number; // timestamp
  u: string; // user ID
  f: string; // first name (user)
  n: string; // last name (user)
  C: string; // Raison sociale (customer)
  e: string; // email (user)
  admin: boolean;
}

export const callAuthToken = async (method: 'GET' | 'POST', body?: any, success?: () => void, error?: () => void) => {
  const fullAuthTokenUrl = `${getUrlOf('ams')}${AUTH_TOKEN_URL}`;

  const rs: Response | void = await fetch(fullAuthTokenUrl, {
    method,
    credentials: 'include',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body
  }).catch(() => {}); // required to handle Network Error
  if (trySaveJwtFromAmsAuthTokenResponse(rs)) {
    success && success();
    return;
  }

  if (error) {
    error();
  }
};

export const getConnectedSsoUser = async () => {
  const rs: Response = await fetch(buildSSOLoginUrl(), {
    method: 'GET',
    credentials: 'include',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    })
  }); // required to handle Network Error
  return (await rs.json()) as AuthAuthorizeResult;
};

export const getConnectedAmsUser = async () => {
  const response: Response = await fetch(buildAMSAuthTokenUrl(), {
    method: 'GET',
    credentials: 'include',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    })
  }); // required to handle Network Error
  return response?.headers.get('user-id');
};

interface AuthLoginData {
  identifiant: string;
  mot_de_passe: string;
}

interface AuthLoginResult {
  error: number;
  message: string;
  user: boolean;
}

interface AuthAuthorizeResult {
  error: number;
  message: string;
  redirect: string;
  userId: string;
}

export const buildSSOLoginUrl = () => {
  const parameters = new URLSearchParams({
    client_id: AUTH_CLIENT_ID,
    response_type: 'code',
    redirect_uri: buildAMSAuthTokenUrl(),
    scope: 'user'
  });
  return `${getUrlOf('auth')}${AUTH_AUTHORIZE_URL}?${parameters.toString()}`;
};

export const buildAMSAuthTokenUrl = () => {
  return `${getUrlOf('ams')}${AUTH_TOKEN_URL}`;
};

export const getPostAuthLoginUrl = () => {
  const parameters = new URLSearchParams({
    client_id: AUTH_CLIENT_ID
  });
  return `${getUrlOf('auth')}${AUTH_LOGIN_URL}?${parameters.toString()}`;
};

export const connectUserOnSso = async (loginData: AuthLoginData) => {
  const parameters = new URLSearchParams({
    ...loginData
  });
  const authLoginResponse = await fetch(getPostAuthLoginUrl(), {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    }),
    body: parameters.toString()
  }); // required to handle Network Error
  const authLoginResult = (await authLoginResponse?.json()) as AuthLoginResult;
  return authLoginResult.user;
};

const callAMSAuthTokenWithSSOCode = async (amsAuthTokenUrl: string) => {
  const amsAuthTokenResponse = await fetch(amsAuthTokenUrl, {
    method: 'GET',
    credentials: 'include',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    })
  }).catch(() => {}); // required to handle Network Error
  return amsAuthTokenResponse;
};

const trySaveJwtFromAmsAuthTokenResponse = (response: Response | void) => {
  const jwt = response?.headers.get('Jwt');
  if (jwt) {
    sessionStorage.setItem(SESSION_STORAGE_JWT, jwt);
    // Add a cookie to know how to display the login form.
    const decodedJwt: User = JwtDecode(jwt);
    if (decodedJwt.u) {
      Cookies.set('isExaprintClient', 'true', { domain: `${getCookieDomain()}`, expires: 365, path: '/' });
    }

    // Keep it for goodies designer
    Cookies.set('jwt', jwt, {
      domain: `${getCookieDomain()}`
    });

    return true;
  }
  return false;
};

export const authLogout = async () => {
  await fetch(getAuthLogoutUrl(), {
    method: 'GET',
    mode: 'no-cors',
    credentials: 'include',
    headers: new Headers({
      'X-Requested-With': 'XMLHttpRequest'
    })
  });
};

export const globalLogout = async () => {
  await fetch(getAmsLogoutUrl(), {
    method: 'GET',
    mode: 'no-cors',
    credentials: 'include',
    headers: new Headers({
      'X-Requested-With': 'XMLHttpRequest'
    })
  });

  sessionStorage.removeItem(SESSION_STORAGE_CUSTOMER_NUMBER);
  sessionStorage.removeItem(SESSION_STORAGE_AUTH_USER_ID);
  sessionStorage.removeItem(SESSION_STORAGE_JWT);

  // Keep it for goodies designer
  Cookies.set('jwt', '', {
    domain: `${getCookieDomain()}`
  });

  // update wisp context
  if (typeof window !== 'undefined' && window?.wisp?.updateContext) {
    window.wisp.updateContext({
      custom: {
        lang: window.wisp.context?.custom?.lang,
        isLoggedIn: 'false'
      }
    });
  }

  reloadPageOnSharedComponent();
};

export const reloadPageOnSharedComponent = () => {
  //In share component mode (AMS) we need to refresh the page after we got logged
  // for the PHP to update the page content (can be different when logged)
  if (process.env.SHARED_COMPONENT_MODE) {
    document.location.reload();
  }
};

const connectUser = async (loginData: AuthLoginData) => {
  return (await connectUserOnSso(loginData)) && (await refreshUserFromSSO(true));
};

export const refreshUserFromAMS = async () => {
  await callAuthToken('GET');
};

/**
 * @returns true if SSO user was different from local stored user,
 * meaning SSO user has changed or logged out
 */
export const refreshUserFromSSO = async (pushGtmLoginEvent: boolean = false) => {
  const [ssoUser, amsUserId] = await Promise.all([getConnectedSsoUser(), getConnectedAmsUser()]);
  const previousSsoUserId = sessionStorage.getItem(SESSION_STORAGE_AUTH_USER_ID);
  const userHasChange = !!ssoUser?.userId && previousSsoUserId !== ssoUser?.userId;
  const userHasLogout = !ssoUser?.userId && !!previousSsoUserId;
  const amsAuthTokenUrlWithSsoCode = ssoUser.redirect;
  if (userHasChange || !amsUserId) {
    // We need to call AMS to inform the user has changed (update PHP session) and generate a new JWT for us
    if (amsAuthTokenUrlWithSsoCode) {
      const authTokenResponse = await callAMSAuthTokenWithSSOCode(amsAuthTokenUrlWithSsoCode);
      const hasJWT = trySaveJwtFromAmsAuthTokenResponse(authTokenResponse);
      if (hasJWT) {
        sessionStorage.setItem(SESSION_STORAGE_AUTH_USER_ID, ssoUser.userId);
        if (pushGtmLoginEvent && process.env.SHARED_COMPONENT_MODE) {
          gtmPushLoginEvent();
        }
        reloadPageOnSharedComponent();
        return true;
      }
    }
  } else if (userHasLogout) {
    await globalLogout();
    return true;
  }
  return false;
};

export const amsLogin = async (loginData: AmsLoginData, success?: () => void, error?: () => void) => {
  await callAuthToken('POST', JSON.stringify(loginData), success, error);
};

export const getLostPasswordUrl = () => {
  return `${getUrlOf('auth')}/api/v1/lostpassword`;
};

export const authLogin = async (loginData: AuthLoginData, apolloClient: ApolloClient.ApolloClient<object>, success?: () => void, error?: () => void) => {
  if (await connectUser(loginData)) {
    await apolloClient.resetStore();
    success && success();
    return;
  }

  if (error) {
    error();
  }
};

export const getAmsLogoutUrl = () => {
  return `${getUrlOf('ams')}/index/logout`;
};

export const setLastUrl = async () => {
  await fetch(`${getUrlOf('ams')}${SET_LAST_URL}?url=${encodeURIComponent(window.location.href)}`, { credentials: 'include' });
};

export const getAuthLogoutUrl = () => {
  return `${getUrlOf('auth')}/api/v1/logout`;
};
