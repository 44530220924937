import React, { FC, useState, useEffect } from 'react';
import { isLoggedIn, useIsRegisteredNonBuyer, useIsReseller, useGetLogin } from '../hooks/useLogin';
import bellIcon from '../../assets/icons/bell_wisp.svg';
import styled from 'styled-components';
import { useIsWindowWidthBelow } from '../hooks/useWindowSize';
import { MOBILE_SIZE } from '../common/constants';

const wispStyle = (wispContainerDisplay: boolean, isMobile: boolean) => {
  const width = isMobile ? '100%' : '22%';

  return {
    visibility: wispContainerDisplay ? 'hidden' : 'visible',
    opacity: wispContainerDisplay ? 0 : 1,
    width: wispContainerDisplay ? 0 : width,
    'z-index': 1
  };
};

export const WispNotifications: FC = () => {
  const useLoginResult = useGetLogin();
  const isMobile = useIsWindowWidthBelow(MOBILE_SIZE);
  /* get and display wisp campaign notification container */
  const wisp = typeof window !== 'undefined' ? document?.getElementById('wisp_container') : null;
  const [wispContainerDisplay, setWispContainerDisplay] = useState(false);
  const isLogged = isLoggedIn(useLoginResult);
  const isReseller = useIsReseller();
  const isRegisteredNonBuyer = useIsRegisteredNonBuyer();
  const clientContact = useLoginResult?.data?.clientContact;
  const isValidSiret =
    useLoginResult.data?.clientContactFull?.Siret !== undefined &&
    useLoginResult.data?.clientContactFull?.Siret !== null &&
    useLoginResult.data?.clientContactFull?.Siret !== '';

  useEffect(() => {
    /* update wisp context to display the right campaigns to the user */
    if (isLogged && window?.wisp?.ready && window?.wisp?.updateContext) {
      window?.wisp?.updateContext({
        custom: {
          ...window.wisp?.context?.custom, // get previous context
          isLoggedIn: isLogged?.toString(),
          isReseller: (isLogged && isReseller)?.toString(),
          isRegisteredNonBuyer: (isLogged && isRegisteredNonBuyer)?.toString(),
          NbCommandes: (clientContact && parseInt?.(clientContact?.NbCommandes)) || undefined,
          IDSegment: (clientContact && parseInt?.(clientContact?.IDSegment)) || undefined,
          RetentionStatusId: clientContact?.RetentionStatusId || undefined,
          IDTier: clientContact?.IDTier || undefined,
          Dormeur: clientContact?.Dormeur || undefined,
          DateInscription: clientContact ? clientContact?.DateInscription : undefined,
          DatePremiereCommande: clientContact?.ClientContactInfos?.DatePremiereCommande || undefined,
          DateDeuxiemeCommande: clientContact?.ClientContactInfos?.DateDeuxiemeCommande || undefined,
          DateDerniereCommande: clientContact?.ClientContactInfos?.DateDerniereCommande || undefined,
          IDFamilleProduitDernierCommande: clientContact?.ClientContactInfos?.IDFamilleProduitDerniereCommande || undefined,
          isValidSiret: isValidSiret?.toString()
        }
      });
    }
  }, [isLogged, isRegisteredNonBuyer, isRegisteredNonBuyer]);

  return (
    <div
      onClick={() => {
        if (wisp) Object?.assign(wisp?.style, wispStyle(wispContainerDisplay, isMobile));
        setWispContainerDisplay(!wispContainerDisplay);
      }}
      className="wisp wisp_extra_count"
    >
      <StyledBellNotification src={bellIcon} alt="notification" />
    </div>
  );
};

const StyledBellNotification = styled.img`
  width: 22px;
  height: 35px;
  vertical-align: middle;
  padding: 0;
  color: #323232;
`;
